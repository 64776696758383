import React from 'react';
import "./loading.css";
const loading = () => {
  return (
    <div className="loader">
        <div>RIOSTAR</div>
        <div>Loading</div>
        <span></span>
    </div>
  )
}

export default loading