import React from 'react';
import { Slide } from 'react-awesome-reveal';
import "./header.css";
import { NavLink } from 'react-router-dom';
const header = () => {
  return (
    <>
    <Slide triggerOnce direction='down' >
      <div className="header_container">
                      {/* LOGO */}
            <div className="section1">
              RIOSTAR
            </div>
                       {/* LINKS */}
            <div className="section2">
              <NavLink to="/"> <li>Home</li> </NavLink>
              <NavLink to="/about"> <li>About</li> </NavLink>
              <NavLink to="/products"> <li>Products</li> </NavLink>
              <NavLink to="/personalized"> <li>Personalized</li> </NavLink>
              <NavLink to="/contact"> <li>Contact</li> </NavLink>
            </div>
                       {/* DISTRIBUTOR BUTTON */}
            <div className="section3">
              <NavLink to="/distributor">
                <button> Become Distributor</button>
              </NavLink>
            </div>
      </div>

      <div className="mobile_header_links">
              <NavLink to="/"> <li>Home</li> </NavLink>
              <NavLink to="/about"> <li>About</li> </NavLink>
              <NavLink to="/products"> <li>Products</li> </NavLink>
              <NavLink to="/personalized"> <li>Personalized</li> </NavLink>
              <NavLink to="/contact"> <li>Contact</li> </NavLink>

      </div>
      </Slide>
    </>
  )
}

export default header
