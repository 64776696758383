import React, {Suspense, lazy} from 'react'
import Header from './components/header/header';
import Loader from './components/loader/loading';
import { BrowserRouter, Route , Routes } from 'react-router-dom';
import Footer from './components/footer/footer';
import Distributor from './pages/dist/distributor';
const Home = lazy(() => import('./pages/home/home'));
const Contact = lazy(() => import('./pages/contact/contact'));
const Personalized = lazy(() => import('./pages/personalized/personalized'));
const Products = lazy(() => import('./pages/products/products'));
const About = lazy(() => import('./pages/about/about'));

const App = () => {
  return (
    <>
    <BrowserRouter>
      <Header />
      <Suspense fallback={<Loader/>}> 
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/personalized" element={<Personalized />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/distributor" element={<Distributor />} />
        </Routes>
        <Footer />
        </Suspense>
    </BrowserRouter>
    </>
  )
}

export default App
