import React from "react";
import "../contact/contact.css";


import { Fade } from "react-awesome-reveal";
export default function Distributor() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Submitting your form...");
    const formData = new FormData(event.target);

    formData.append("access_key", "91059dcf-e946-4aa2-8810-713f763d9686");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <>
    <Fade>
     
    </Fade>
    <div class="contact-form">
    <h2>Become a Distributor</h2>
      <form onSubmit={onSubmit}>
      <input type="hidden" name="subject" value="A response from distributor form" />
      <input type="hidden" name="from_name" value="Riostar Distributor" />
      <div className="contact-form__group">
        <label for="name">Name:</label>
        <input type="text" placeholder="Type your name" id="name" name="name" required />
      </div>
      <div className="contact-form__group">
        <label for="email">Email:</label>
        <input type="email" placeholder="Type your email address" id="email" name="email" required />
      </div>
      <div className="contact-form__group">
        <label for="phone">Phone:</label>
        <input type="tel" placeholder="Type your phone no" id="phone" name="phone" required />
      </div>
      <div className="contact-form__group">
        <label for="state">State:</label>
        <input type="text" placeholder="Type your state" id="state" name="state" required />
      </div>
      <div className="contact-form__group">
        <label for="city">City:</label>
        <input type="text" placeholder="Type your city" id="city" name="city" required />
      </div>
     
      <div className="contact-form__group">
        <label for="message">Message:</label>
        <textarea id="message" placeholder="Type your message" name="message" required></textarea>
      </div>
      <button type="submit">Submit</button>

      </form>
      <div className="status" >{result}</div>

    </div>
    </>
  );
}