import React from 'react';
import Riostar from "../../assets/images/just_riostar.png";
import "./footer.css"
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const footer = () => {
  return (
    <>
    <Fade triggerOnce >
    <div className="footer_img">
         <img src={Riostar} alt="footer_riostar" />
    </div>
       
      <div className="footer">
        <div className="footer_bottom">
            <div className='footer_left'>
               <h2>RIOSTAR</h2>
               
               <p>Riostar water is an Packaged Drinking Water brand in India. Available in 200ml, 500ml, 1 Litre.</p>
               <br/>
               <h3>Our Handles</h3>
               <p>
                <Link target='main' to="https://instagram.com" ><li> <XIcon /></li></Link>
                <Link target='main' to="https://www.instagram.com/riostarwater" ><li> <InstagramIcon /></li></Link>
                <Link target='main' to="https://m.facebook.com/61555964523640/" ><li> <FacebookIcon /></li></Link>
                <Link target='main' to="https://api.whatsapp.com/send?phone=917014416333&text=Hello Sir, I got riostar contact from website" ><li> <WhatsAppIcon /></li></Link>
               
                
               </p>
            </div>



            <div className="footer_right">
                <h3>Contact Details</h3>
                <p>Rio Star Water, SKS Industrial Area, B-21 RIICO,Reengus, Parasrampura, Rajasthan 332404</p>
                <p>Phone No: +91 9829600839, +91 7014416333</p>
                <p>Email Id : contact@riostar.in</p>
                <Link to="https://maps.app.goo.gl/5rh6yatD24enbTHJ7">
                  <button> View on Map </button>
                </Link>
            </div>

            
        </div>

            <div className="copyright">
              &#169; Copyrighted 2024 All rights reserved | Balaji Industries
            </div>

            <div className="credits">
             <a href="https://www.linkedin.com/in/mhimanuhu/" target='main' >Designed & Developed by Manu Sharma</a>
            </div>
    </div>

    </Fade>
    </>
  )
}

export default footer